//全局请求路径
export const AXIOS_DEFAULT_CONFIG = {
  baseURL: '',
  timeout: 200000
}
//sentry配置id
export const sentry = 'https://b737ed169d6843ec8eea412852203e09@sentry.io/1226863'
//代理前缀
export const prefixPath = process.env.NODE_ENV !== 'production' ? '/api-platform/' : '/api-platform/'
//全局主色调
export const mainColor = '#1890FF'
//登录成功跳转路由地址
export const indexRouter = 'index'
// 获取静态皮肤地址
export const staticDir = `/data_files/`
export const power = true
export const isOpenAuth = true
export const enableDynamic = true
// 个人中心-新闻中心代理前缀
export const newsPrefixPath = process.env.NODE_ENV !== 'production' ? '/api-qixuan/' : '/api-qixuan/'
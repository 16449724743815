export default [
  {
    path: '/solicitOpinions',
    name: 'solicitOpinions',
    component: () => import('@/views/solicit-opinions/list/index.vue'),
    meta: {
      title: '征集意见',
      isFirstPage: true
    }
  },
  {
    path: '/feedbackOpinions',
    name: 'feedbackOpinions',
    component: () => import('@/views/solicit-opinions/feedback-opinions/index.vue'),
    meta: {
      title: '填写意见',
      isFirstPage: true
    }
  }
]
export function mainButton () {
  return {
    'add': '21new', //新增
    'append': 'plus-circle', //添加
    'bacth': 'file-copy', //批量
    'publish': 'fabu', //发布
    'set': 'xitongshezhi22', //设置
    'audit': 'check-square', //审核 && 通过
    'sync': 'sync', //同步
    'archive': 'folder', //归档
    'import': 'Import', //导入
    'export': 'export', //导出
    'sort': 'orderedlist', //排序
    'remove': 'minus-circle', //移除
    'search': 'search', //查询
    'submit': 'check-circle', //提交
    'sure': 'filedone', //确定
    'save': 'save', //保存
    'refresh': 'undo' //刷新
  }
}
export function secondButton () {
  return {
    'copy': 'diff', //复制
    'dimiss': 'shenpibohui', //驳回
    'history': 'Field-time', //历史
    'end': 'stop', //终止
    'over': 'jurassic_over', //结束
    'restore': 'huifu', //恢复
    'edit': 'edit', //修改
    'see': 'eye', //查看
    'reset': 'undo', //重置
    'back': 'left-circle', //返回
    'delete': 'delete', //删除
    'prev': 'verticalright', //上一步
    'next': 'verticalleft', //下一步
    'left': 'zuoyi-copy', //左移
    'right': 'youyi-copy', //右移
    'cancel': 'file-excel', //取消
    'close': 'close-circle', //关闭
    'waive': 'close-circle', //放弃
    'print': 'fabu', //打印
    'list': 'liebiao', //列表
    'chart': 'zhuzhuang', //图表
    'review': 'shenqing', //发起复评
    'publicity': 'ico', //组内公示
    'bacthGhost': 'file-copy', //批量  需要没有主颜色
    'fullScreen': 'quanping1' //全屏
  }
}
export function sortLevel () {
  return {
    'add': 1, //新增
    'append': 2, //添加
    'copy': 3, //复制
    'bacth': 4, //批量
    'publish': 5, //发布
    'set': 6, //设置
    'audit': 7, //审核
    'dimiss': 8, //驳回
    'sync': 9, //同步
    'archive': 10, //归档
    'history': 11, //历史
    'export': 12, //导出
    'import': 13, //导入
    'sort': 14, //排序
    'end': 15, //终止
    'over': 16, //结束
    'restore': 17, //恢复
    'remove': 18, //移除
    'search': 19, //查询
    'edit': 20, //修改
    'see': 21, //查看
    'reset': 22, //重置
    'back': 23, //返回
    'delete': 24, //删除
    'submit': 25, //提交
    'sure': 26, //确定
    'save': 27, //保存
    'prev': 28, //上一步
    'next': 29, //下一步
    'left': 30, //左移
    'right': 31, //右移
    'cancel': 32, //取消
    'close': 33, //关闭
    'waive': 34, //放弃
    'print': 35, //打印
    'refresh': 36, //同步、刷新
    'list': 37, //列表
    'chart': 38, //图表
    'review': 39, //发起复评
    'publicity': 40, //组内公示
    'bacthGhost': 41, //批量
    'fullScreen': 42 //全屏
  }
}

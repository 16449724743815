import oaLogin from '../../views/oa/login.vue'
import oaTodo from '../../views/oa/todo.vue'
const oaMsg = () => import('../../views/oa/msg.vue')

export default [{
	path: '/oa/login',
	name: 'oaLogin',
	component: oaLogin,
}, {
	path: '/oa/todo/:itemId',
	name: 'oaTodo',
	component: oaTodo,
}, {
	path: '/oa/msg/:msgId',
	name: 'oaMsg',
	component: oaMsg,
}]
const homePage = () => import('@/views/news-core/home-page')
const noticeAnnouncement = () => import('@/views/news-core/notice-announcement')
const newsDetail = () => import('@/views/news-core/news-detail')
export default [
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage,
    meta: {
      title: '首页',
      isFirstPage: true
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: noticeAnnouncement,
    meta: {
      title: '通知公告',
      isFirstPage: true
    }
  },
  {
    path: '/party_lead',
    name: 'party_lead',
    component: noticeAnnouncement,
    meta: {
      title: '党建引领',
      isFirstPage: true
    }
  },
  {
    path: '/probity_garden',
    name: 'probity_garden',
    component: noticeAnnouncement,
    meta: {
      title: '廉洁园地',
      isFirstPage: true
    }
  },
  {
    path: '/union_news',
    name: 'union_news',
    component: noticeAnnouncement,
    meta: {
      title: '工会动态',
      isFirstPage: true
    }
  },
  {
    path: '/youth_league_voice',
    name: 'youth_league_voice',
    component: noticeAnnouncement,
    meta: {
      title: '青团之声',
      isFirstPage: true
    }
  },
  {
    path: '/pioneer_innovation',
    name: 'pioneer_innovation',
    component: noticeAnnouncement,
    meta: {
      title: '创业创新',
      isFirstPage: true
    }
  },
  {
    path: '/culture_position',
    name: 'culture_position',
    component: noticeAnnouncement,
    meta: {
      title: '文化阵地',
      isFirstPage: true
    }
  },
  {
    path: '/model_strength',
    name: 'model_strength',
    component: noticeAnnouncement,
    meta: {
      title: '榜样力量',
      isFirstPage: true
    }
  },
  {
    path: '/public_benefit_activities',
    name: 'public_benefit_activities',
    component: noticeAnnouncement,
    meta: {
      title: '公益活动',
      isFirstPage: true
    }
  },
  {
    path: '/systemIndex',
    name: 'systemIndex',
    component: noticeAnnouncement,
    meta: {
      title: '制度与流程索引',
      isFirstPage: true
    }
  },
  {
    path: '/manager_idea',
    name: 'manager_idea',
    component: noticeAnnouncement,
    meta: {
      title: '管理者思想',
      isFirstPage: true
    }
  },
  {
    path: '/important_news',
    name: 'important_news',
    component: noticeAnnouncement,
    meta: {
      title: '重大新闻',
      isFirstPage: true
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail,
    meta: {
      title: '新闻详情'
    }
  }
]
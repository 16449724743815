const ListPage = () => import('@/views/system')

export default [
  {
    path: '/systemList',
    name: 'systemList',
    component: ListPage,
    meta: {
      title: '制度与流程索引',
      isFirstPage: true
    }
  }
]
<template>
  <header class="iv-header">
    <div class="iv-main-width header-content">
      <div class="iv-header-title iv-flex">
        <img :src="logoSimple" style="height: 32px;"/>
        <div class="iv-m-l-24 iv-fs-16 iv-fc-45" v-if="(currentRouteName !== 'index' && currentRouteName !== 'login')">
          <span class="iv-pointer" @click="toIndexPage">
            <Iconfont class="iv-fc-45" type="home"></Iconfont> 首页
          </span>
          <span class="iv-pointer iv-fc-45" :class="{'iv-fc-85': i === crumbsList.length - 1}" @click="toNextPage(item)"
                v-for="(item, i) in crumbsList" :key="item.name">
            <Iconfont class="iv-fc-45" type="right"></Iconfont> {{ item.title }}
          </span>
        </div>
      </div>
      <div class="iv-text-right iv-header-right iv-flex">
        <div v-if="showOrgList.length > 0" class="org-content">
          <div v-for="item in showOrgList" :key="item.identityType" @click="onMenuClick(item)" class="org-item">
            <img :src="item.icon" :alt="item.systemName" class="org-avatar">
            <span class="org-name">{{ item.systemName }}</span>
          </div>
        </div>

        <!-- <Tooltip content="查看消息" placement="bottom">
          <span class="iv-pointer" @click.stop="handleInfoClick" v-clickoutside="handleClose">
            <span>
              <Badge count="0" class="iv-m-r-24 iv-bell-badge">
                <Iconfont class="iv-pointer iv-fs-24" type="bell"></Iconfont>
              </Badge>
            </span>
            <transition name="pull-up">
              <info-scroll
                class="info-scroll"
                v-if="isInformation"
                @setIsNewMsg="setIsNewMsg"
                @handleClose="handleClose"
                @on-oprate-click="onOprateClick"
              ></info-scroll>
            </transition>
          </span>
        </Tooltip> -->
        <Dropdown trigger="click" @on-click="onUserClick">
          <img class="iv-hader-headImg iv-pointer" :src="photoSrc" @error="nofind">
          <DropdownMenu slot="list">
            <DropdownItem class="iv-user-li iv-p-t-12 iv-p-b-12" name="user">
              <span class="iv-fs-16 iv-fc-85 iv-fw-b">我的</span>
            </DropdownItem>
            <DropdownItem class="iv-user-li iv-p-t-12 iv-p-b-12" name="logout">
              <span class="iv-fs-16 iv-fc-85 iv-fw-b">退出登录</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </header>
</template>

<script>
import orgConfig from './config.js'
import infoScroll from './info-scroll'
import Iconfont from '@/components/iconfont'
// import { mapGetters, mapMutations } from 'vuex'
import clickoutside from '@/directives/clickoutside'

const indexLogo = require('@/assets/images/index-logo.png')
const robotLogo = require('@/assets/images/robot-logo.png')
export default {
  data() {
    return {
      isNewMsg: false,
      isInformation: false,
      userInfo: {},
      orgList: [],
      photoSrc: '',
      logoSimple: indexLogo,
    }
  },
  mounted() {
    sessionStorage.setItem('idMap', JSON.stringify({
      'YWX01': 'orgId',
      'YWX03': 'fullId',
      'YWX04': 'legId',
      'YWX06': 'planId',
      'YWX05': 'cultureId'
    }))
    sessionStorage.setItem('nameMap', JSON.stringify({
      'YWX01': 'orgName',
      'YWX03': 'fullName',
      'YWX04': 'legName',
      'YWX06': 'planName',
      'YWX05': 'cultureName'
    }))
  }
  ,
  directives: {
    clickoutside
  }
  ,
  computed: {
    currentRouteName() {
      return this.$route.name
    }
    ,
    crumbsList() {
      return this.$store.state.crumbsList
    },
    showOrgList() {
      return this.orgList.filter(item => item.show)
    }
  }
  ,
  watch: {
    $route: {
      handler() {
        if (this.$route.path != '/' && this.$route.name != 'login') {
          this.orgList = Object.keys(orgConfig).map(item => {
            return {
              ...orgConfig[item],
              identityType: item
            }
          })
          this.getUserInfo()
          this.getOrgList()
        }
        this.logoSimple = this.$route.name == 'chatrobot' ? robotLogo : indexLogo
      }
      ,
      immediate: true
    }
  }
  ,
  methods: {
    // ...mapMutations([
    //   'SKINLAYOUT',
    //   'ISNEWMESSAGE'
    // ]),
    getOrgList() {
      sessionStorage.setItem('identityType', JSON.stringify([]))
      sessionStorage.setItem('identityOrgs', JSON.stringify([]))
      this.batchGetUrl()
    }
    ,
    batchGetUrl() {
      let urls = ['/api-uum/boe/userIdentity/Dj', '/api-uum/boe/userIdentity/Gj', '/api-uum/boe/userIdentity/Tj', '/api-uum/boe/userIdentity/Qx', '/api-uum/boe/userIdentity/Qh']
      for (let i = 0; i < urls.length; i++) {
        this.getSysUrl(urls[i])
      }
    }
    ,
    getSysUrl(url) {
      this.$ajax({
        url: url,
        method: 'get',
        data: {}
      }).then(({data}) => {
        if (!data.data) return
        let currentItem = {}
        this.orgList.map(item => {
          if (item.identityType == data.data.identityType) {
            Object.assign(item, {...data.data, show: true})
            currentItem = item
          }
        })
        console.log('currentItem', currentItem)
        console.log('orgList', this.orgList)
        if (data.data.identityType == 'YWX01') {
          this.$emit('onQRCodeLoad', data.data.systemUrl)
        }
        let types = JSON.parse(sessionStorage.getItem('identityType') || '[]')
        types.push(data.data.identityType)
        let list = JSON.parse(sessionStorage.getItem('identityOrgs') || '[]')
        list.push(currentItem)
        sessionStorage.setItem('identityType', JSON.stringify(types))
        sessionStorage.setItem('identityOrgs', JSON.stringify(list))
      })
    }
    ,
    getUserInfo() {
      this.$ajax({
        url: 'api-uum/uuminfo/getUserInfo',
        method: 'get',
        data: {}
      }).then(({data}) => {
        console.log("head===", data)
        this.userInfo = data.data
        sessionStorage.setItem('userId', this.$encode(this.userInfo.userCode))
        sessionStorage.setItem('userName', this.$encode(this.userInfo.userName))
        sessionStorage.setItem('userCode', this.$encode(this.userInfo.userCode))
        sessionStorage.setItem('userPhone', this.$encode(this.userInfo.mobile))
        sessionStorage.setItem('userEmail', this.$encode(this.userInfo.email || 'www@163.com'))
        sessionStorage.setItem('idCardNum', this.$encode(this.userInfo.idCardNum))
        sessionStorage.setItem('birthday', this.$encode(this.userInfo.birthday))//政治生日
        sessionStorage.setItem('userGender', this.$encode(this.userInfo.sex))
        sessionStorage.setItem('userPolitical', this.$encode(this.userInfo.polity))
        sessionStorage.setItem('userDutyName', this.$encode(this.userInfo.dutyName))
        sessionStorage.setItem('politicsOrgName', this.$encode(this.userInfo.orgName))
        this.getUserOrganization(this.userInfo.userId, 'YWX01', 'orgId', 'orgName') //党
        this.getUserOrganization(this.userInfo.userId, 'YWX03', 'fullId', 'fullName') //会
        this.getUserOrganization(this.userInfo.userId, 'YWX04', 'legId', 'legName', 'principalUser') //团
        // this.getUserOrganization(this.userInfo.userId, 'YWX06', 'planId', 'planName') //企划管理
        this.getUserOrganization(this.userInfo.userId, 'YWX05', 'cultureId', 'cultureName') //文化宣传
        this.getUserHeaderPhoto()
        //2024-11-13不上线
        // this.loadRobotScript()
      })
    },
    loadRobotScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://sdk.zhike.360.cn/zk_sdk.js?username=${this.$encode(this.userInfo.userName)}
          &loginName=${this.$encode(this.userInfo.userCode)}&enterpriseName=${this.$encode(this.userInfo.orgName || '')}`;
      script.setAttribute('_id', 'f2edc8c3e9ec4e96883fa454a081b319');
      script.setAttribute('_lid', '0b638fd891d14f1ca62e99061b43a9d6');
      document.head.appendChild(script);
    },
    getUserOrganization(userId, bizLineCode, id, name, principalUser) {
      this.$ajax({
        url: 'api-general/module/portal/user/getInfo',
        method: 'get',
        data: {
          userId: userId,
          bizLineCode: bizLineCode
        }
      }).then(({data}) => {
        const info = data.data
        if (!info) return
        sessionStorage.setItem(id, this.$encode(info.orgId))
        // 组织名称改为简称
        sessionStorage.setItem(name, this.$encode(info.shortName || info.orgName))
        //因业务速需要这里增加工会组织全称
        if (name == 'fullName') {
          sessionStorage.setItem('LaborUnionName', info.orgName)
        }

        principalUser ? sessionStorage.setItem(principalUser, this.$encode(info.principalUser)) : ''
      })
    }
    ,
    //面包屑跳转
    toNextPage(item) {
      if (this.currentRouteName === item.name) return
      this.$router.push({
        name: item.name,
        query: item.query
      })
    }
    ,
    onUserClick(name) {
      console.log(name)
      if (name === 'logout') {
        const element = document.getElementById('___360_znkf_enter_container');
        if (element) {
          element.style.display = 'none'
        }

        // 清空session 返回登录页
        sessionStorage.clear()
        this.$router.push({
          name: 'login'
        })
      } else if (name === 'user') {
        this.$router.push({
          name: 'userInfo'
        })
      }
    },
    //跳转首页
    toIndexPage() {
      this.$router.push('/index')
    }
    ,
    onOprateClick() {
      //this.getMessageList()
    }
    ,
    setIsNewMsg(type) {
      this.isNewMsg = type
    }
    ,
    // 查找是否有新消息
    getNewLists(oList, nList) {
      let isNew = false
      for (let i = 0; i < nList.length; i++) {
        if (!(oList.includes(nList[i]))) {
          isNew = true
          break
        }
      }
      return isNew
    }
    ,
    setMessageList() {
      let list = JSON.parse(localStorage.getItem('messageFlashList'))
      if (list && list.length) {
        let isNew = this.getNewLists(list, this.newMessageList)
        if (isNew) {
          localStorage.setItem('messageFlashList', JSON.stringify(this.newMessageList))
          this.isInformation = true
          setTimeout(() => {
            this.isInformation = false
          }, 4500)
        }
        return
      }
      localStorage.setItem('messageFlashList', JSON.stringify(this.newMessageList))
      this.isInformation = true
      setTimeout(() => {
        this.isInformation = false
      }, 4500)
    }
    ,
    // 获取未读列表
    getMessageList() {
      this.$ajax({
        url: 'innermessage/unreadNumber/get',
        method: 'GET',
        data: {}
      }).then(({data}) => {
        if (data.data.count > 0) {
          //this.ISNEWMESSAGE(true)
          this.newMessageNum = data.data.count > 99 ? '99+' : data.data.count
          this.newMessageList = data.data.ids
          this.setMessageList()
        } else {
          //this.ISNEWMESSAGE(false)
          this.newMessageNum = 0
        }
        //开始遍历提示
        setTimeout(() => {
          //this.getMessageList()
        }, this.loopTime)
      })
    }
    ,
    // 消息框点击事件
    handleInfoClick(event) {
      this.isInformation = true
    }
    ,
    handleClose(e) {
      this.isInformation = false
    }
    ,
    //切换类型
    onMenuClick(item) {
      let url = '';
      if (item.identityType === 'YWX01') {
        url = `${item.systemUrl}?token=${sessionStorage.getItem('token')}`
      } else if (item.identityType === 'YWX05') {
        url = `${item.systemUrl}interpage.html?access_token=${sessionStorage.getItem('token')}`
      } else {
        url = `${item.systemUrl}#/?access_token=${sessionStorage.getItem('token')}`
      }
      window.open(url)
    }
    ,
    //获取头像
    getUserHeaderPhoto() {
      this.$ajax({
        url: 'api-uum/module/portal/user/getPhoto',
        method: 'get',
        data: {
          userCode: this.userInfo.userCode
        },
        responseType: 'arraybuffer'
      }).then((response) => {
        this.photoSrc = `data:image/png;base64,${btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
      })
    }
    ,
    nofind() {
      var img = event.srcElement
      img.src = require('@/assets/images/user/header-3.png')
      img.onerror = null
    }
  }
  ,
  components: {
    Iconfont,
    infoScroll
  }
}
</script>

<style lang="scss" scoped>
.iv-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.09);

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .org-content {
    display: flex;
    gap: 38px;
    align-items: center;
    margin-right: 32px;

    .org-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .org-avatar {
        width: 20px;
      }

      .org-name {
        color: #0E1824;
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  .iv-header-title {
    height: 64px;
  }

  .iv-header-right {
    height: 64px;
    -webkit-box-pack: right;
    -webkit-justify-content: right;
    -ms-flex-pack: right;
    justify-content: flex-end;

    .iv-hader-headImg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .iv-flex {
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  @keyframes myFlash {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes myFlash {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .iv-flash {
    animation: myFlash 1s infinite;
    -webkit-animation: myFlash 1s infinite;
  }

  // .iv-bell-badge {
  //   /deep/ .ivu-badge-count {
  //     border: none;
  //     height: 16px;
  //     line-height: 16px;
  //     top: -5px;
  //     min-width: 16px;
  //     padding: 0;
  //   }
  // }
  .info-scroll {
    position: absolute;
    top: 60px;
    right: -38px;
    z-index: 110;
    min-width: 460px;
  }
}
</style>
<style lang="scss">
.iv-desktop-li {
  dl {
    overflow: hidden;

    .iconfont {
      font-size: 40px;
    }

    p {
      width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.iv-user-li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &:last-child {
    border: none;
  }
}
</style>

const ListPage = () => import('@/views/association')
const enrollPage = () => import('@/views/association/list-associations')
const details = () => import('@/views/association/detail-accociations')
const PhotoAlbum = () => import('@/views/association/components/photo-album-dtail')
const application = () => import('@/views/association/association-application-form')

export default [
  {
    path: '/association',
    name: 'association',
    component: ListPage,
    meta: {
      title: '协会'
    }
  },
  {
    path: '/listAssociations',
    name: 'listAssociations',
    component: enrollPage,
    meta: {
      title: '我的协会申请'
    }
  },
  {
    path: '/details',
    name: 'details',
    component: details,
    meta: {
      title: '协会详情'
    }
  },
  {
    path: '/PhotoAlbumDetail',
    name: 'PhotoAlbumDetail',
    component: PhotoAlbum,
    meta: {
      title: '相册'
    }
  },
  {
    path: '/associationApplicationForm',
    name: 'associationApplicationForm',
    component: application,
    meta: {
      title: '协会申请信息填写'
    }
  }
]
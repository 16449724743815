const ListPage = () => import('@/views/message')

export default [
  {
    path: '/messageList',
    name: 'messageList',
    component: ListPage,
    meta: {
      title: '我的消息',
      isFirstPage: true
    }
  }
]
<template>
  <div class="oa-page">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import {
  indexRouter
} from '@/config'

export default {
  name: 'oaLogin',
  data() {
    return {
    }
  },
  created() {
    this.getLogin()
  },
  methods: {
    getLogin() {
      this.$ajax({
        url: `/api-sso/oa/login?ticket=${this.$route.query.ticket}`,
        method: 'GET',
      }).then((res) => {
        if (res.data.code === 0) {
          sessionStorage.setItem('token', res.data.data.access_token);
          this.$router.push({
            name: indexRouter
          });
        }
      }).catch(err => {
        //err.data.code
      })
    }
  }
}
</script>

<style lang="scss">
@import url("oaStyle.css");
</style>

const ListPage = () => import('@/views/appraising-declare')
const enrollPage = () => import('@/views/appraising-declare/detail')

export default [
  {
    path: '/appraisingList',
    name: 'appraisingList',
    component: ListPage,
    meta: {
      title: '评优申报',
      isFirstPage: true
    }
  },
  {
    path: '/appraisingDetail',
    name: 'appraisingDetail',
    component: enrollPage,
    meta: {
      title: '报名'
    }
  }
]
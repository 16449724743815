import Vue from 'vue'
import Modal from './confirm-modal'
let confirmModal = getNewInstance()
/* eslint-disable no-new */
function getNewInstance () {
  let instance = new Vue({
    render (h) {
      return h(Modal)
    }
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  return instance.$children[0]
}
export default {
  info (...args) {
    return confirmModal.info(...args)
  },
  warning (...args) {
    return confirmModal.warning(...args)
  },
  error (...args) {
    return confirmModal.error(...args)
  }
}

export default [
  {
    path: '/voiceAdviceList',
    name: 'voiceAdviceList',
    component: () => import('@/views/voice-advice/list/index.vue'),
    meta: {
      title: '心声建议',
      isFirstPage: true
    }
  },
  {
    path: '/createVoiceAdvice',
    name: 'createVoiceAdvice',
    component: () => import('@/views/voice-advice/create/index.vue'),
    meta: {
      title: '提交心声建议'
    }
  },
  {
    path: '/voiceAdviceViewInfo',
    name: 'voiceAdviceViewInfo',
    component: () => import('@/views/voice-advice/view-info/index.vue'),
    meta: {
      title: '查看心声建议'
    }
  },
  {
    path: '/voiceAdviceAudit',
    name: 'voiceAdviceAudit',
    component: () => import('@/views/voice-advice/audit/index.vue'),
    meta: {
      title: '心声建议审核'
    }
  },
  {
    path: '/handleAdvice',
    name: 'handleAdvice',
    component: () => import('@/views/voice-advice/handle-advice/index.vue'),
    meta: {
      title: '心声建议处理'
    }
  },
  {
    path: '/viewResult',
    name: 'viewResult',
    component: () => import('@/views/voice-advice/view-Result/index.vue'),
    meta: {
      title: '查看反馈结果'
    }
  },
  { //从待办（已办查看）跳转过来
    path: '/viewCompletedResults',
    name: 'viewCompletedResults',
    component: () => import('@/views/voice-advice/view-completed-results/index.vue'),
    meta: {
      title: '查看处理结果'
    }
  }
]
const ListPage = () => import('@/views/review')
const setPage = () => import('@/views/review/set-scoring')
const scoringPage = () => import('@/views/review/scoring')
const resultPage = () => import('@/views/review/scoring-result')

export default [
  {
    path: '/reviewList',
    name: 'reviewList',
    component: ListPage,
    meta: {
      title: '评审打分',
      isFirstPage: true
    }
  },
  {
    path: '/reviewScoring',
    name: 'reviewScoring',
    component: setPage,
    meta: {
      title: '设置打分'
    }
  },
  {
    path: '/reviewGrade',
    name: 'reviewGrade',
    component: scoringPage,
    meta: {
      title: '打分'
    }
  },
  {
    path: '/reviewResult',
    name: 'reviewResult',
    component: resultPage,
    meta: {
      title: '查看评审结果'
    }
  }
]
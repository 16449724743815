import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    crumbsList: [],
    pageSize: 5
  },
  mutations: {
    CRUMBS_LIST: (state, payload) => {
      state.crumbsList = payload
    },
    PAGE_SIZE: (state, payload) => {
      state.pageSize = payload
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    pageSize (state) {
      return state.pageSize
    }
  }
})

const ListPage = () => import('@/views/project-declare')
const projectDetail = () => import('@/views/project-declare/detail')

export default [
  {
    path: '/projectList',
    name: 'projectList',
    component: ListPage,
    meta: {
      title: '项目申报',
      isFirstPage: true
    }
  },
  {
    path: '/projectDetail',
    name: 'projectDetail',
    component: projectDetail,
    meta: {
      title: '报名'
    }
  }
]
import $api from '@/plugins/api'
import iView from 'iview'
import router from '@/router'
import Store from '@/store'
const {dutyChain} = $api
class Tips {
  success (msg = '') {
    if (!msg) {
      console.error('接口提示消息为空，请检查接口')
      return
    }
    iView.Message.success(msg)
  }
  error (msg) {
    if (!msg) {
      console.error('接口提示消息为空，请检查接口')
      return
    }
    iView.Message.error(msg)
  }
  condition () {}
}
class EnviromentTips extends Tips {
  condition (res) {
    if (typeof res.data === 'string') {
      try {
        if (res.config.url.indexOf('bookletdownload') === -1) { //下载word流不做格式转换
          res.data = JSON.parse(res.data)
        }
      } catch (err) {
        const msg = '登录超时，请重新登录'
        localStorage.clear()
        router.push('/')
        this.error(msg)
        return Promise.reject(new Error(msg))
      }
      return Promise.resolve(res)
    }
  }
}
class InterfaceUnusualTips extends Tips {
  condition (res) {
    const { config } = res
    if (res.data && res.data.code && (res.data.code != '2000' && res.data.code !== 0 && res.data.code != 200)) {
      // switch (res.data.code) {
      //   case '10003':
      //     //重复提交
      //     res.data.message = '请勿重复提交'
      //     break
      //   case '5000':
      //     //服务端异常
      //     break
      // }
      //阻止错误信息提示
      if (!res.data.message && typeof res.data.data === 'object') {
        let {resultCode} = res.data.data, message = (Store.state.tips.reqText || {})[resultCode]
        !config.preventMessage && this.error(`${decodeURI(message)}`)
        return Promise.reject(message || res)
      }
      !config.preventMessage && this.error(`${decodeURI(res.data.message)}`)
      return Promise.reject(res)
    }
    return Promise.resolve(res)
  }
}
const enviromentTips = new EnviromentTips()
const interfaceUnusualTips = new InterfaceUnusualTips()
function formatData (data) {
  if (data) {
    let keys
    if (Array.isArray(data)) {
      data.map(item => {
        formatData(item)
      })
    } else {
      keys = Object.keys(data)
      keys.forEach((key) => {
        if (Object.prototype.toString.call(data[key]) === '[object String]' && data[key].indexOf('%26') > -1) {
          data[key] = data[key].replace(/%26/g, '&')
        } else if (Object.prototype.toString.call(data[key]) === '[object Object]' || Object.prototype.toString.call(data[key]) === '[object Array]') {
          formatData(data[key])
        }
      })
    }
  }
}
const status200 = async function (type, res = {}) {
  if (type !== 200) return 'next'
  if (res.config.url.indexOf('portal/user/getPhoto') != -1) {
    return res
  }
  await formatData(res.data.data)
  //临时环境登录失败提示
  await enviromentTips.condition(res)
  //系统接口异常提示
  await interfaceUnusualTips.condition(res)
  return res
}
//创建职责链
const statusDuty = dutyChain(status200)
//定义职责链
export {statusDuty}

const ListPage = () => import('@/views/questionnaire')
const DetailPage = () => import('@/views/questionnaire/detail')
export default [
  {
    path: '/questionnaireList',
    name: 'questionnaireList',
    component: ListPage,
    meta: {
      title: '调查问卷',
      isFirstPage: true
    }
  },
  {
    path: '/questionnaireDetail',
    name: 'questionnaireDetail',
    component: DetailPage,
    meta: {
      title: '调查问卷'
    }
  }
]
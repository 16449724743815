export default [
  {
    path: '/recruitingPartyMembers',
    name: 'recruitingPartyMembers',
    component: () => import('@/views/recruiting-party-members/index.vue'),
    meta: {
      title: '发展党员',
    }
  },
  {
    path: '/recruitingPartyMembersProgressKanban',
    name: 'recruitingPartyMembersProgressKanban',
    component: () => import('@/views/recruiting-party-members/progress-kanban/index.vue'),
    meta: {
      title: '发展党员',
      isFirstPage: true
    }
  },
  {
    path: '/addRecruitingPartyMembers', //第一步申请
    name: 'addRecruitingPartyMembers',
    component: () => import('@/views/recruiting-party-members/steps/first-step'),
    meta: {
      title: '申请入党'
    }
  },
  {
    path: '/modalAddThought', //上传思想汇报 
    name: 'modalAddThought',
    component: () => import('@/views/recruiting-party-members/steps/thought-report/add-thought/index.vue'),
    meta: {
      title: '上传思想汇报'
    }
  },
  {
    path: '/modalAddInspectUsers', //上传培养教育意见
    name: 'modalAddInspectUsers',
    component: () => import('@/views/recruiting-party-members/steps/thought-report/add-inspect-users/index.vue'),
    meta: {
      title: '上传培养教育意见'
    }
  }
]
const declarationSignList = () => import('@/views/declaration-signing')
const declarationSignDetail = () => import('@/views/declaration-signing/detail')

export default [
  {
    path: '/declarationSignList',
    name: 'declarationSignList',
    component: declarationSignList,
    meta: {
      title: '员工职业操守宣言签署'
    }
  },
  {
    path: '/declarationSignDetail',
    name: 'declarationSignDetail',
    component: declarationSignDetail,
    meta: {
      title: '员工职业操守宣言签署'
    }
  }
]
const publicSentimentDealtWith = () => import('@/views/public-sentiment/dealt-with')
const publicSentimentToExamine = () => import('@/views/public-sentiment/to-examine')

export default [
  {
    path: '/publicSentimentDealtWith',
    name: 'publicSentimentDealtWith',
    component: publicSentimentDealtWith,
    meta: {
      title: '舆情管理',
      isFirstPage: true
    }
  },
  {
    path: '/publicSentimentToExamine',
    name: 'publicSentimentToExamine',
    component: publicSentimentToExamine,
    meta: {
      title: '舆情管理',
      isFirstPage: true
    }
  }
]
<template>
  <Button
    :class="`uit-operation-item-${type} iv-fs-16`"
    @click="handler(...arguments)"
    v-bind="$attrs"
    :type="buttonType">
    <IconFont
      v-if="!noIcon"
      :type="mainButton[type] || secondButton[type] || 'edit'"
      style="vertical-align: middle;"
      class="iv-fs-18 iv-m-r-8"></IconFont>
    <slot></slot>
  </Button>
</template>

<script>
import { Button } from 'iview'
import { mainButton, secondButton } from './config'
import IconFont from '@/components/iconfont'
export default {
  name: 'ButtonGlobal',
  data () {
    return {
      buttonType: '',
      mainButton: {},
      secondButton: {}
    }
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    type () {
      this.setButtonType()
    }
  },
  components: {
    Button,
    IconFont
  },
  created () {
    this.mainButton = mainButton.call(this)
    this.secondButton = secondButton.call(this)
    this.setButtonType()
  },
  methods: {
    setButtonType () {
      if (this.mainButton[this.type] != undefined) {
        this.buttonType = 'primary' //primary
      } else {
        this.buttonType = 'ghost'
      }
    },
    handler (data) {
      this.$emit('click', data)
    }
  }
}
</script>

<style lang="scss">
  .ivu-btn-primary {
    background-color: transparent !important;
    color: #2d8cf0 !important;
    &:hover {
      background-color: transparent !important;
      color: #2d8cf0 !important;
    }
  }
</style>

const newsDetail = () => import('@/views/news-core/news-detail')

export default [
  {
    path: '/zdsyNewsDetail',
    name: 'zdsyNewsDetail',
    component: newsDetail,
    meta: {
      title: '制度与流程索引',
      isFirstPage: true
    }
  }
]
import {prefixPath, power, isOpenAuth} from '@/config'
import {getDuty} from './request-config'
import {statusDuty} from './response-config'
import router from '@/router'
import iView from 'iview'
import {CancelToken} from 'axios'
import store from '@/store'
import { getToken } from '@/utils'
const postQueue = new Map()
let timer = null //定时器

// 请求成功回调
export const reqSucCallback = function (config) {
  //考虑到浏览器刷新，必须存储到localStorage
  if ((getToken() || '').trim()) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${getToken() || ''}`
    })
  }
  const cancelWhiteList = ['tree/secret/single/getTree']
  const isCancelWhite = cancelWhiteList.some(item => (config.url.indexOf(item) != -1))
  if (/(post|put)/i.test(config.method) && !isCancelWhite) {
    // 如果已经存在，我们要取消这个请求
    let source = CancelToken.source()
    config.cancelToken = source.token
    let storeConfigData = JSON.parse(JSON.stringify(config.data))
    if (Object.prototype.hasOwnProperty.call(storeConfigData, '_csrf')) {
      delete storeConfigData._csrf
    }
    config._data = config.url + JSON.stringify(storeConfigData)
    if (postQueue.has(config._data)) {
      source.cancel('请求过于频繁，请稍后再试')
      return config
    }
    postQueue.set(config._data, source)
  }
  //职责链开始
  if (config.url.indexOf('api-') == -1 && config.url.indexOf('sso') == -1) {
    config.baseURL += prefixPath
  }
  return getDuty.exec(config.method.toLowerCase(), config).then(() => {
    //post、put请求时添加loading
    const needIntercept = ['post', 'put', 'POST', 'PUT'], whiteList = ['sso/token', 'api-general/module/portal/user/getUser']
    if (needIntercept.includes(config.method) && !sessionStorage.getItem('isShowMask')) {
      const isWhiteUrl = whiteList.some(item => (config.url.indexOf(item) != -1))
      if (!isWhiteUrl) {
        sessionStorage.setItem('isShowMask', config.url)
        timer = setTimeout(() => { //如果接口超过1秒还在请求就显示loading
          iView.Spin.show()
        }, 1000)
      }
    }
    return config
  })
}
// 请求失败回调
export const reqFailCallback = function () {}
//响应成功回调
export const resSucCallback = function (res) {
  if (/(post|put)/i.test(res.config.method) && postQueue.has(res.config._data)) {
    setTimeout(() => {
      postQueue.delete(res.config._data)
      delete res.config._data
    }, 200)
  }
  if (['post', 'put', 'POST', 'PUT'].includes(res.config.method) && res.config.url.indexOf(sessionStorage.getItem('isShowMask')) != -1) {
    sessionStorage.removeItem('isShowMask')
    iView.Spin.hide()
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  }
  const data = statusDuty.exec(res.status, res)
  return data.then((res) => {
    if (typeof res === 'object') {
      if (res.data.accessToken) {
        sessionStorage.setItem('token', res.data.accessToken)
      }
      return Promise.resolve(res)
    }
  })
}
// 响应失败回调
export const resFailCallback = function ({response, message, config = {}}) {
  const msg = response?.data?.message || message
  !config.preventMessage && msg && iView.Message.error(msg)
  if (/(post|put)/i.test(config.method) && postQueue.has(config._data)) {
    setTimeout(() => {
      postQueue.delete(config._data)
      delete config._data
    }, 200)
  }
  if (['post', 'put', 'POST', 'PUT'].includes(config.method) && config.url.indexOf(sessionStorage.getItem('isShowMask')) != -1) {
    sessionStorage.removeItem('isShowMask')
    iView.Spin.hide()
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  }
  if (response?.status === 403 && isOpenAuth) {
    let arr = store?.state?.accessAuthori || []
    let _url = '/' + config.url.split(config.baseURL)[1]
    if (!arr.includes(_url)) {
      arr.push(_url)
    }
    store.commit('ACCESSAUTHORI', arr)
  }
  if (response?.status === 401 && power) {
    localStorage.clear()
    sessionStorage.clear()
    if (response.data.data.indexOf('http') > -1) {
      window.location.href = response.data.data
    } else {
      router.push({path: '/'})
    }
  }
  return Promise.reject(response, 'sdd')
}

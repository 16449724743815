<script>
import { Modal } from 'iview'
import Button from '@/components/button'
import Layout from '@/components/modal/layout'
import IconFont from '@/components/iconfont'
export default {
  data () {
    return {
      isShow: false,
      color: '',
      icon: '',
      options: {}
    }
  },
  created () {
    this.colorMap = {
      error: '#F5222D',
      info: '#52C41A',
      warning: '#FAAD14'
    }
    this.iconMap = {
      error: 'info-circle-fill',
      info: 'check',
      warning: 'info-circle-fill'
    }
  },
  methods: {
    open (options, type) {
      this.color = this.colorMap[type]
      this.icon = this.iconMap[type]
      this.isShow = true
      this.options = options || {
        title: '您确定要进行此操作吗？',
        content: '可能会导致部分数据丢失'
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    info (options) {
      return this.open(options, 'info')
    },
    warning (options) {
      return this.open(options, 'warning')
    },
    error (options) {
      return this.open(options, 'error')
    },
    confirm () {
      const close = () => {
        this.close()
      }
      this.resolve(close)
    },
    close () {
      this.isShow = false
      // 如果直接点击了关闭 就改变promise状态为reject
      this.reject('关闭模态')
      this.reject = this.resolve = null
    }
  },
  render (c) {
    return c('Modal', {
      props: {
        value: this.isShow,
        width: 416,
        size: 'small',
        maskClosable: false,
        title: this.options.title
      },
      ref: 'confirmModal',
      class: {
        'iv-confirm-modal': true
      },
      on: {
        'on-cancel': () => {
          this.close()
        }
      },
      scopedSlots: {
        header: props => {
          return c('div', {
            class: 'ivu-modal-header-inner',
            style: {color: this.color}
          }, [
            c('IconFont', {props: {type: this.icon}}),
            c('span', {class: 'iv-fw-b'}, this.options.title)
          ])
        },
        default: props => {
          return c('Layout', this.options.content)
        },
        footer: props => {
          return c('div', {
            class: 'iv-text-center'
          }, [
            c(Button, {
              props: {
                type: 'sure',
                noIcon: true
              },
              nativeOn: {
                click: () => {
                  this.confirm()
                }
              }
            }, '确定'),
            c(Button, {
              props: {
                type: 'cancel',
                noIcon: true
              },
              nativeOn: {
                click: () => {
                  this.close()
                }
              }
            }, '取消')
          ])
        }
      }
    })
  },
  components: {
    Layout,
    IconFont,
    Button,
    Modal
  }
}
</script>
<style lang="scss" scoped>
 /deep/ .iconfont {
  vertical-align: -2px;
  font-size: 21px;
  margin-right: 16px;
  font-weight: normal;
}
</style>

<template>
  <div class="oa-page">
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import {frameNameMap, goMsgDetailPage} from "@/utils/todo"

export default {
  data() {
    return {}
  },
  created() {
    this.getLogin()
  },
  methods: {
    getLogin() {
      this.$ajax({
        url: `/api-sso/oa/login?v5ticket=${this.$route.query.v5ticket}`,
        method: 'GET',
      }).then((res) => {
        if (res.data.code === 0) {
          const token = res.data.data.access_token;
          sessionStorage.setItem('token', token);
          const orgId = sessionStorage.getItem('listOrgId');
          this.getDetail(this.$route.params.itemId, token, orgId)
        }
      }).catch(err => {

      })
    },
    getDetail(msgId, token, orgId) {
      this.$ajax({
        url: `/api-uum/todo/getTodoItemInfo?itemId=${msgId}`,
        method: 'GET',
      }).then((res) => {
        let data = res.data.data;
        goMsgDetailPage(msgId, token, orgId, data.systemCode, data.operates[0].operateUrl, data.businessId);
      })
    }
  }
}
</script>

<style lang="scss">
@import url("oaStyle.css");
</style>

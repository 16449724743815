const cleanCertificate = () => import('@/views/clean-certificate')
const certificationApply = () => import('@/views/clean-certificate/certification-apply/index.vue')
const certificationFeedback = () => import('@/views/clean-certificate/certification-feedback') // 廉洁证明反馈

export default [
  {
    path: '/cleanCertificate',
    name: 'cleanCertificate',
    component: cleanCertificate,
    meta: {
      title: '廉洁证明管理',
      isFirstPage: true
    }
  },
  {
    path: '/certificationApply',
    name: 'certificationApply',
    component: certificationApply,
    meta: {
      title: '发起申请',
      isFirstPage: true
    }
  },
  {
    path: '/certificationFeedback',
    name: 'certificationFeedback',
    component: certificationFeedback,
    meta: {
      title: '廉洁证明反馈',
      isFirstPage: true
    }
  }
]
import Vue from 'vue'
import VueRouter from 'vue-router'
import { routerBeforeEach, routerAfterEach } from '@/config/interceptors/router'
import appraising from './appraising-declare' //评优申报
import project from './project-declare' //项目申报
import activity from './activity' //活动报名
import review from './review' //评审打分
import system from './system' //制度索引
import dealt from './dealt-list' //我的待办列表
import message from './message' //我的消息列表
import difficult from './difficult' //困难申请
import cleanCertificate from './clean-certificate' //廉洁证明
import user from './user' //个人信息。党组织信息等
import newsCenterRouter from './news-center'
import newsDetail from './news-detail'
import declarationSigning from './declaration-signing' //职业操守宣言
import publishSentiment from './public-sentiment' //舆情管理
import voiceAdviceList from './voice-advice'
import questionnaire from './questionnaire' // 满意度调查
import solicitOpinions from './solicitOpinions' // 征集意见
import recruitingPartyMembers from './recruiting-party-members' // 发展党员
import association from './association' // 协会
import oa from "./oa/index.js";
const IndexPage = () => import('@/views/index')
const RobotPage = () => import('@/views/chatrobot')
// const LoginPage = () => import('@/views/login')
const newsCenter = () => import('@/views/news-center')
const ssoLogin = () => import('@/views/sso-login')
Vue.use(VueRouter)

export const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   component: LoginPage
  // },
  {
    path: '/',
    name: 'login',
    component: ssoLogin
  },
  {
    path: '/index',
    name: 'index',
    component: IndexPage
  },
  {
    path: '/chatrobot',
    name: 'chatrobot',
    component: RobotPage
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: newsCenter,
    children: [
      ...newsCenterRouter,
      ...newsDetail
    ]
  },
  ...oa,
  ...appraising,
  ...project,
  ...activity,
  ...review,
  ...system,
  ...dealt,
  ...message,
  ...difficult,
  ...user,
  ...declarationSigning,
  ...cleanCertificate,
  ...publishSentiment,
  ...questionnaire,
  ...voiceAdviceList,
  ...publishSentiment,
  ...questionnaire,
  ...solicitOpinions,
  ...recruitingPartyMembers,
  ...association,
]

const router = new VueRouter({
  routes
})

router.beforeEach(routerBeforeEach)
router.afterEach(routerAfterEach)

export default router
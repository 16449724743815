import iView from 'iview'
import store from '@/store'
import {
	indexRouter
} from '@/config'
import {
	getToken
} from '@/utils'
import{
whiteList
} from "../whiteList.js"
//修改loadingBar-color
iView.LoadingBar.config({
	color: '#fefa01'
})
// const whiteList =['oaLogin','oaMsg','oaTodo'];
export const routerBeforeEach = function(to, from, resolve) {
	//whiteList白名单 ，这里可以不走登录 
	if (whiteList.indexOf(to.name) > -1) {
		resolve();
		return;
	}
	if (to.query.access_token) {
		sessionStorage.setItem('token', to.query.access_token)
	}
	const token = getToken()
	const crumbsList = store.state.crumbsList
	let routes = crumbsList.length ? crumbsList : JSON.parse(sessionStorage.crumbsList || '[]')
	//未登录状态，重定向到登录
	// if ((!token && to.name !== 'login')) {
	//   resolve('/')
	//   return
	// } else 
	if (token && to.name === 'login') {
		//未登录状态，重定向到首页
		resolve({
			name: indexRouter
		})
		return
	}
	// 检查是否使用window.open
	if (to.meta.open && !from.meta.open && from.fullPath !== '/' && window.name !== to.fullPath) {
		window.open(`#${to.fullPath}`, to.fullPath)
		return
	}
	let index = routes.findIndex(item => item.name === to.name)
	//如果index为最后一个，视为缓存，暂不处理
	if (to.name !== 'index' && to.name !== 'login') {
		if (routes.length && index === routes.length - 1) {
			store.commit('CRUMBS_LIST', routes.length ? [...routes.slice(0, index), Object.assign({}, routes[
				index], {
				query: to.query
			})] : routes)
		} else if (index < 0) { //新增
			if (to.meta.isFirstPage) {
				routes = []
			}
			store.commit('CRUMBS_LIST', routes.concat({
				name: to.name,
				title: to.meta.title,
				query: to.query
			}))
		} else {
			if (index === 0) {
				store.commit('CRUMBS_LIST', [routes[0]])
			} else {
				store.commit('CRUMBS_LIST', [...routes.slice(0, index)])
			}
		}
	} else {
		routes = []
		store.commit('CRUMBS_LIST', routes)
	}
	sessionStorage.setItem('crumbsList', JSON.stringify(store.state.crumbsList))
	iView.LoadingBar.start()
	resolve()
}
export const routerAfterEach = function(to, from) {
	iView.LoadingBar.finish()
}
const ListPage = () => import('@/views/activity')
const activityDetail = () => import('@/views/activity/detail') 

export default [
  {
    path: '/activityList',
    name: 'activityList',
    component: ListPage,
    meta: {
      title: '最新活动'
    }
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    component: activityDetail,
    meta: {
      title: '活动报名'
    }
  }
]
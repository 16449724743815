import { trigger, findComponentUpward } from '@/utils'
//引入动态最新版本table组件特性
import { Table } from 'view-design'
//父类
function Decorate () {
  let decorateList = {
    console () {
      let g = window
      let logMaps = ['log', 'warn', 'error']
      g.console = g.console || {}
      logMaps.forEach(item => {
        if (!g.console[item]) g.console[item] = () => {}
      })
    }
  }
  return {
    add (namespace, fn) {
      if (decorateList[namespace]) {
        console.warn(`${namespace} 已存在`)
        return
      }
      decorateList[namespace] = fn
    },
    exec () {
      for (let i in decorateList) {
        decorateList[i] && decorateList[i].apply(this, arguments)
      }
    }
  }
}
let decorate = Decorate()
//兼容IE9下tabs animate默认值
decorate.add('iview-tabs', (Vue) => {
  let UA = window.navigator.userAgent.toLowerCase()
  let isIE9 = UA && UA.indexOf('msie 9.0') > 0
  if (isIE9) Vue.options.components.Tabs.options.props.animated.default = false
})
//装饰Input组件trim
decorate.add('iview-input', (Vue) => {
  const options = Vue.options.components.Input.options
  const mounted = options.mounted || []
  // options.props.size.default = 'small'
  mounted.push(function () {
    let input = this.$el.querySelector(this.type === 'textarea' ? 'textarea' : 'input')
    input.addEventListener('compositionstart', function (e) {
      e = e || window.event
      e.target.composing = true
    })
    input.addEventListener('compositionend', function (e) {
      e = e || window.event
      if (!e.target.composing) return
      e.target.composing = false
      trigger(e.target, 'input')
    })
  })
})
//兼容select在overflow情况下无法完全展示
decorate.add('iview-datapicker', (Vue) => {
  // let vm
  // const inserted = options.directives.TransferDom.inserted
  const options = Vue.options.components.DatePicker.options
  options.created = options.created || []
  options.props.transfer.default = true
  options.props.editable.default = false
  options.props.splitPanels.default = true
  options.props.uitClass = {
    type: String,
    default: ''
  }
  // uit测试类名埋点暂时忽略
  // options.created.push(function () {
  //   vm = this
  // })
  // options.directives.TransferDom.inserted = function (elm, dir, vnode) {
  //   inserted.apply(this, arguments)
  //   let classList = [...elm.classList]
  //   if (classList.every(item => item.indexOf('uit-') === -1) && vm.uitClass) {
  //     elm.children[0] && elm.children[0].classList.add(vm.uitClass)
  //   }
  // }
})
//兼容下拉框在overflow情况下无法完全展示
decorate.add('iview-select', (Vue) => {
  /*eslint-disable*/
  let options = Vue.options.components.Select.options
  let dropdownCls = options.computed.dropdownCls
  options.props.transfer.default = true
  // options.props.size.default = 'small'
  options.props.uitClass = {
    type: String,
    default: ''
  }
  options.computed.dropdownCls = function () {
    let res = dropdownCls.apply(this, arguments)
    return {
      ...res,
      [this.uitClass]: true
    }
  }
  // let handleKeydown = options.methods.handleKeydown
  options.methods.handleKeydown = function (e) {
    if (e.key === 'Backspace') {
      return
    }
    if (this.visible) {
      e.preventDefault()
      if (e.key === 'Tab') {
        e.stopPropagation()
      }
      if (e.key === 'Escape') {
        e.stopPropagation()
        this.hideMenu()
      }
      if (e.key === 'ArrowUp') {
        this.navigateOptions(-1)
      }
      if (e.key === 'ArrowDown') {
        this.navigateOptions(1)
      }
      if (e.key === 'Enter') {
        if (this.focusIndex === -1) return this.hideMenu()
        const optionComponent = this.flatOptions[this.focusIndex]
        const option = this.getOptionData(optionComponent.componentOptions.propsData.value)
        if (!option.label) {
          this.$emit('on-enter', this, option)
        }
        // 还是没有，我们直接return
        if (!option.label) return
        this.onOptionClick(option)
      }
    } else {
      const keysThatCanOpenSelect = ['ArrowUp', 'ArrowDown']
      if (keysThatCanOpenSelect.includes(e.key)) this.toggleMenu(null, true)
    }
  }
})
//button大小调整
decorate.add('iview-button', (Vue) => {
  /*eslint-disable*/
  let options = Vue.options.components.Button.options
  // options.props.size.default = 'small'
})
//table大小调整
decorate.add('iview-table', (Vue) => {
  Vue.component('Table', Table)
  /*eslint-disable*/
})
//数字输入框大小调整
decorate.add('iview-inputNumber', (Vue) => {
  /*eslint-disable*/
  // let options = Vue.options.components.InputNumber.options
  // options.props.size.default = 'small'
})
//兼容dropdown在overflow情况下无法完全展示
decorate.add('iview-dropdown', (Vue) => {
  /*eslint-disable*/
  let options = Vue.options.components.Dropdown.options
  options.props.transfer.default = true
})
//tabs组件埋点装饰
decorate.add('iview-tab-pane', (Vue) => {
  let tabPaneOptions = Vue.options.components.TabPane.options
  let tabsOptions = Vue.options.components.Tabs.options
  let tabsMounted = tabsOptions.mounted || []
  let tabCls = tabsOptions.methods.tabCls
  tabPaneOptions.props.uitClass = {
    type: String,
    default: ''
  }
  //使用装饰类
  tabsOptions.methods.tabCls = function (item) {
    let res = tabCls.apply(this, arguments)
    let current = this.$children.find(child => child.label === item.label)
    return [
      ...res,
      current ? current.uitClass || '' : ''
    ]
  }
  tabsMounted.push(function () {
    //console.log(this.navList, 999)
  })
})
//兼容树形菜单在点击第二次时候是取消
decorate.add('iview-tree', (Vue) => {
  let options = Vue.options.components.Tree.options, components = options.components, TreeNode = components.TreeNode
  TreeNode.components.CollapseTransition = {
    name: 'CollapseTransition',
    functional: true,
    render (h, { children }) {
      const data = {
        on: {}
      }
      return h('transition', data, children)
    }
  }
  options.beforeDestroy = [function () {
    this.$off('on-check')
    this.$off('on-selected')
    this.$off('toggle-expand')
  }]
  function mixin () {
    if (this.flatState && this.flatState[0]) {
      let node = this.getInjectSelectNode(this)
      this.$set(this.flatState[0].node, 'expand', true)
      this.$set(this.flatState[0].node, 'selected', true)
      this.$emit('on-select-change', this.getSelectedNodes())
      this.firstIgnore = false
    }
  }
  //修改handleSelect方法
  options.props.checkStrictly = {type: Boolean, default: false}
  options.methods.updateTreeUp = function (nodeKey) {
    const parentKey = this.flatState[nodeKey].parent;
    if (typeof parentKey == 'undefined' || this.checkStrictly) return;

    const node = this.flatState[nodeKey].node;
    const parent = this.flatState[parentKey].node;
    if (node.checked == parent.checked && node.indeterminate == parent.indeterminate) return; // no need to update upwards

    if (node.checked == true) {
        this.$set(parent, 'checked', parent[this.childrenKey].every(node => node.checked));
        this.$set(parent, 'indeterminate', !parent.checked);
    } else {
        this.$set(parent, 'checked', false);
        this.$set(parent, 'indeterminate', parent[this.childrenKey].some(node => node.checked || node.indeterminate));
    }
    this.updateTreeUp(parentKey);
  }
  options.methods.updateTreeDown = function (node, changes = {}) {
    if (this.checkStrictly) return;
    for (let key in changes) {
        this.$set(node, key, changes[key]);
    }
    if (node[this.childrenKey]) {
        node[this.childrenKey].forEach(child => {
            this.updateTreeDown(child, changes);
        });
    }
  }
  options.methods.handleSelect = function (nodeKey) {
    const node = this.flatState[nodeKey].node
    if (!this.multiple) {
      const currentSelectedKey = this.flatState.findIndex(obj => obj.node.selected)
      if (currentSelectedKey >= 0 && currentSelectedKey !== nodeKey) this.$set(this.flatState[currentSelectedKey].node, 'selected', false)
    }
    !node.selected && this.$set(node, 'selected', !node.selected)
    // this.$set(node, 'expand', !node.expand)
    this.$emit('on-select-change', this.getSelectedNodes())
    this.notHandleCheck = false
    this.firstIgnore = false
  }
  //修改handleCheckt方法
  options.methods.handleCheck = function ({ checked, nodeKey }) {
    const node = this.flatState[nodeKey].node
    this.notHandleCheck = false
    this.$set(node, 'checked', checked)
    this.$set(node, 'indeterminate', false)
    this.updateTreeUp(nodeKey)
    this.updateTreeDown(node, {checked, indeterminate: false})
    this.$emit('on-check-change', this.getCheckedNodes(), {
      checked,
      node,
      index: nodeKey
    })
  }
  options.inject = {
    getInjectSelectNode: {
      from: 'getInjectSelectNode',
      default () {
        return ''
      }
    }
  }
  //混入created
  options.created.push(function () {
    //首次展开忽略，防止重复设置
    this.firstIgnore = this.notHandleCheck = true
    mixin.call(this)
  })
  //混入watch
  // options.watch.flatState = function () {
  //   this.firstIgnore && mixin.call(this, false)
  // }
  // let handler = options.watch.data[0].handler
  // options.watch.data = function () {
  //   handler.apply(this, arguments)
  //   this.firstIgnore = this.notHandleCheck
  //   this.$nextTick(() => {
  //     this.notHandleCheck = true
  //   })
  // }
  let prefixCls = 'ivu-tree'
  TreeNode.methods.handleExpand = function () {
    const item = this.data
    // if (item.disabled && !item.checkDisabled) return
    if (item[this.childrenKey].length === 0) {
      const tree = findComponentUpward(this, 'Tree')
      if (tree && tree.loadData) {
        this.$set(this.data, 'loading', true)
        tree.loadData(item, children => {
          this.$set(this.data, 'loading', false)
          if (children.length) {
            this.$set(this.data, this.childrenKey, children)
            this.$nextTick(() => this.handleExpand())
          }
        })
        return
      }
    }
    TreeNode.computed.arrowClasses = function () {
      return [
        `${prefixCls}-arrow`,
        {
          [`${prefixCls}-arrow-disabled`]: this.data.disabled,
          [`${prefixCls}-arrow-open`]: this.data.expand
        }
      ]
    }
    if (item[this.childrenKey] && item[this.childrenKey].length) {
      this.$set(this.data, 'expand', !this.data.expand)
      this.dispatch('Tree', 'toggle-expand', this.data)
    }
  }
})
//装饰radio change方法，供外部拦截使用（党建项目）
decorate.add('iview-radio', (Vue) => {
  const options = Vue.options.components.Radio.options
  if (!options.created) options.created = []
  //无法从原型上重写方法，在created中重写
  options.created.push(function () {
    this.change = (e) => {
      if (this.$listeners.beforeRadioChange) {
        this.$listeners.beforeRadioChange()
          .then(() => {
            this.$options.methods.change.call(this, e)
          })
        e.target.checked = false
      } else {
        this.$options.methods.change.call(this, e)
      }
    }
  })
})
//装饰Checkbox input方法，供外部拦截使用（党建项目）
decorate.add('ivu-checkbox-group', (Vue) => {
  const options = Vue.options.components.Checkbox.options
  options.methods.change = function (event) {
    if (this.disabled) {
      return false
    }
    const checked = event.target.checked
    this.currentValue = checked
    const value = checked ? this.trueValue : this.falseValue
    this.$emit('input', value, event)
    if (this.group) {
      this.parent.change(this.model, this.label, value);
    } else {
      this.$emit('on-change', value, this.label)
      this.dispatch('FormItem', 'on-form-change', value)
    }
  }
})
//装饰CheckboxGroup input方法，将当前选中项的label value传递出去
decorate.add('ivu-checkbox', (Vue) => {
  const options = Vue.options.components.CheckboxGroup.options
  options.methods.change = function (data, label, value) {
    this.currentValue = data;
    this.$emit('input', data, label, value);
    this.$emit('on-change', data, label, value);
    this.dispatch('FormItem', 'on-form-change', data);
  }
})

export default {
  install: function (Vue, options) {
    decorate.exec.apply(this, arguments)
  }
}

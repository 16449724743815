const userPage = () => import('@/views/index/components/user-info/personal-information')
const orgPage = () => import('@/views/index/components/user-info/org-target')
const contactPage = () => import('@/views/index/components/user-info/contact')
const developing = () => import('@/views/index/components/user-info/developing.vue')
const twinPage = () => import('@/views/index/components/user-info/contact/twinning')
const contactDetail = () => import('@/views/index/components/user-info/contact/detail')
const resetPassword = () =>import('@/views/reset-password')

export default [
  {
    path: '/userInfo',
    name: 'userInfo',
    component: userPage,
    meta: {
      title: '个人信息',
      isFirstPage: true
    }
  },
  {
    path: '/orgTarget',
    name: 'orgTarget',
    component: orgPage,
    meta: {
      title: '组织目标',
      isFirstPage: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contactPage,
    meta: {
      title: '党员联系员工',
      isFirstPage: true
    }
  },
  {
    path: '/developing',
    name: 'developing',
    component: developing,
    meta: {
      title: '',
      isFirstPage: true
    }
  },
  {
    path: '/twinPage',
    name: 'twinPage',
    component: twinPage,
    meta: {
      title: '结对子员工'
    }
  },
  {
    path: '/contactDetail',
    name: 'contactDetail',
    component: contactDetail,
    meta: {
      title: ''
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: resetPassword,
    meta: {
      title: ''
    }
  }
]
const ListPage = () => import('@/views/difficult')
const applyPage = () => import('@/views/difficult/apply')
const helpList = () => import('@/views/difficult/help')

export default [
  {
    path: '/difficultList',
    name: 'difficultList',
    component: ListPage,
    meta: {
      title: '困难申请',
      isFirstPage: true
    }
  },
  {
    path: '/difficultApply',
    name: 'difficultApply',
    component: applyPage,
    meta: {
      title: '填写申请单'
    }
  },
  {
    path: '/helpList',
    name: 'helpList',
    component: helpList,
    meta: {
      title: '帮扶列表'
    }
  }
]
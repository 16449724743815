<template>
  <div class="iv-info-wrapper">
    <div class="top"></div>
    <!-- <div class="iv-meun">
      <Scroll :height="480">
        <div
          class="iv-meun-item iv-pointer iv-symbol-pl12 iv-symbol-pr12"
          v-for="(item, inx) in groupList"
          :key="inx"
          @click="changeGroup(item, inx)"
          :class="{ 'iv-active': activeIndex === inx }">
          <p :title="item.groupName">{{ item.groupName.substr(0, 5) }}</p>
          <p>{{ item.unReadNum || 0 }}</p>
          <i v-if="!item.readState"></i>
        </div>
      </Scroll>
    </div> -->
    <div class="iv-info">
      <ul class="iv-titUl iv-fw-b iv-text-center iv-fs-16 iv-fc-65">
        <li class="active iv-main-color iv-pointer">
          <span>未读消息<em class="iv-fs-12 iv-m-l-8" v-if="counts > 0">{{ counts }}</em></span>
        </li>
        <li class="iv-pointer" @click.stop="handleMailClick">
          <span>全部消息</span>
        </li>
      </ul>
      <Scroll ref="scroll" :height="407" v-if="noticeList.length" :on-reach-bottom="handleReachBottom" :distance-to-edge="[10, 10]">
        <div
          class="iv-info-item iv-fs-14 iv-m-r-16 iv-m-b-16"
          v-for="(item, inx) in noticeList"
          :key="inx"
          :class="{ 'iv-m-b-16': inx === 0 }">
          <h3 v-if="item.msgTitle">
            <!-- <IconFont
              class="iv-fs-12 i-tubiao"
              :type="(item.urgency === 1 && item.state === 0) ? 'tishi' : item.urgency === 1 ? 'warning-circle-fill' : item.state === 0 ? 'xiaobiaoti' : ''"
              :class="(item.urgency === 1 && item.state === 0) ? 'iv-custom-red iv-m-r-8' : item.urgency === 1 ? 'iv-custom-red iv-m-r-8' : item.state === 0 ? 'iv-custom-blue iv-m-r-8' : ''">
            </IconFont> -->
            <IconFont
              class="iv-fs-14 i-tubiao"
              :type="item.urgency === 1 ? 'qizi1' : ''"
              :class="item.urgency === 1 ? 'iv-custom-red iv-m-r-8' : ''">
            </IconFont>
            {{ item.msgTitle }}
          </h3>
          <p
            :title="item.sendContent"
            class="iv-m-t-8 iv-m-b-8 iv-pointer"
            :class="{'iv-pointer': !item.state}"
            @click="operateMsgClick(item, 'read')">
            {{ item.sendContent }}
          </p>
          <p class="iv-m-b-4">
            <i class="icon iconfont iv-symbol-mr4 icon-time-circle iv-fs-12"></i>
            {{ fnTime(timeFormat(item.sendDate, 'yyyy-MM-dd hh:mm')) }}
            <span
              @click="operateMsgClick(item)"
              class="iv-pull-right iv-pointer iv-main-color"
              >{{ setOperaName(item) }}</span>
              <!-- :class="{'iv-pointer': !item.state || (item.actionType === 2 || item.actionType === 3), 'iv-main-color': (item.actionType === 2 || item.actionType === 3)}" -->
          </p>
        </div>
      </Scroll>
      <div class="iv-no-msg" v-else>
        <img src="~@/assets/images/no-message.png" width="110" alt />
        <p>暂无消息</p>
      </div>
      <div class="iv-operation">
        <p @click.stop="handleReadClick" v-if="noMessage" class="iv-pointer iv-main-color">全部已读</p>
        <!-- <div @click.stop="handleMailClick" class="iv-pointer">
          <i
            class="icon iconfont iv-symbol-mr8 iv-custom-icon-time uit-see-history icon-time-circle"
          ></i>
          <span class="iv-m-r-24">收件箱</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/iconfont'
import {mapMutations} from 'vuex'
import {timeFormat} from '@/utils'
export default {
  data () {
    return {
      groupList: [],
      noticeList: [],
      noMessage: true,
      activeIndex: 0,
      activeName: '',
      currentPage: 1,
      maxPage: 1,
      pageSize: 10,
      counts: null,
      timeFormat: null
    }
  },
  created () {
    this.timeFormat = timeFormat
    this.getNoticeTabs()
  },
  methods: {
    ...mapMutations([
      'ISNEWMESSAGE'
    ]),
    handleReachBottom () {
      if (this.currentPage >= this.maxPage) {
        return
      }
      return new Promise(resolve => {
        this.currentPage += 1
        setTimeout(() => {
          this.$ajax({
            url: 'innermessage/unread/list',
            methods: 'GET',
            data: {
              // groupName: this.activeName,
              currentPage: this.currentPage,
              pageSize: this.pageSize
            }
          }).then(({data}) => {
            this.maxPage = data.maxPage
            this.counts = data.count
            let last = data.data
            for (let i = 0; i < last.length; i++) {
              this.noticeList.push(last[i])
            }
          })
          resolve()
        }, 1000)
      })
    },
    // changeGroup (item, inx) {
    //   this.activeIndex = inx
    //   this.activeName = item.groupName == '全部' ? '' : item.groupName
    //   this.currentPage = 1
    //   this.pageSize = 10
    //   this.noMessage = !!item.unReadNum
    //   this.getNoticeList()
    // },
    getNoticeTabs () {
      // this.$ajax({
      //   url: 'innermessage/group/list',
      //   method: 'GET',
      //   data: {}
      // }).then(({data}) => {
      //   this.groupList = this.setAllGroupList(data.data)
      // }).then(() => {
      //   this.getNoticeList()
      // })
      //this.getNoticeList()
    },
    getNoticeList () {
      this.$ajax({
        url: 'innermessage/unread/list',
        methods: 'GET',
        data: {
          // groupName: this.activeName,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(({data}) => {
        this.maxPage = data.maxPage
        this.counts = data.count
        this.noMessage = !!data.count
        this.noticeList = data.data
      })
    },
    // setAllGroupList (data) {
    //   let num = 0, isR = true
    //   data.length && data.forEach(item => {
    //     num += item.unReadNum
    //     if (!item.readState) {
    //       isR = item.readState
    //     }
    //   })
    //   data.unshift({
    //     groupName: '全部',
    //     readState: isR,
    //     unReadNum: num
    //   })
    //   this.noMessage = !!num
    //   if (!num) {
    //     this.ISNEWMESSAGE(false)
    //   }
    //   return data
    // },
    handleReadClick () {
      this.$ajax({
        url: 'innermessage/allread/update',
        method: 'PUT',
        data: {
          groupName: this.activeName
        }
      }).then(({data}) => {
        this.noMessage = false
        this.currentPage = 1
        this.$emit('on-oprate-click')
        this.getNoticeTabs()
      })
    },
    handleMailClick () {
      let that = this
      this.$router.push({
        name: 'messageList'
      })
      setTimeout(function () {
        that.$emit('handleClose')
      }, 200)
    },
    operateMsgClick (item, type) {
      item.gotoWay = 1
      // if (type) {
      //   this.setReadMsg(item, type)
      //   return
      // }
      switch (item.actionType) {
        case 2:
        case 3:
          this.setReadMsg(item, type, 'jump')
          break
        default:
          this.setReadMsg(item, type)
          break
      }
    },
    setReadMsg (item, type, isDu) {
      if (item.state === 1) {
        this.jump(item, type, isDu)
        return
      }
      this.$ajax({
        url: 'innermessage/read/update',
        method: 'PUT',
        data: {
          ids: item.innerMessageId
        }
      }).then(({data}) => {
        this.currentPage = 1
        this.$emit('on-oprate-click')
        this.getNoticeTabs()
      }).then(res => {
        if (isDu) {
          this.jump(item, type, isDu)
        }
      })
    },
    jump (item, type, isDu) {
      this.$emit('handleClose')
      let _url = ''
      if (item.gotoUrl.split('?')[1]) {
        _url += item.gotoUrl + '&workDone=true'
      } else {
        _url += item.gotoUrl + '?workDone=true'
      }
      if (item.gotoWay === 1) {
        window.location.href = _url
        return
      }
      window.open(_url)
    },
    // 设置操作名
    setOperaName (objValue) {
      let obj = objValue, textName = ''
      switch (obj.actionType) {
        case 2:
        case 3:
          textName = obj.buttonName || '操作'
          break
        default:
          textName = obj.state ? '已读' : '知道了'
          break
      }
      return textName
    },
    fnTime (time) {
      let ptime = new Date(time).getTime()
      const twentyFourHours = 24 * 60 * 60 * 1000
      const fortyEightHours = 24 * 60 * 60 * 1000 * 2
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const today = `${year}-${month}-${day}`
      const todayTime = new Date(today).getTime()
      const yesterdayTime = new Date(todayTime - twentyFourHours).getTime()
      const lastYesterdayTime = new Date(todayTime - fortyEightHours).getTime()
      if (ptime >= todayTime) {
        return '今天 ' + time.split(' ')[1]
      } else if (ptime < todayTime && yesterdayTime <= ptime) {
        return '昨天 ' + time.split(' ')[1]
      } else if (ptime < yesterdayTime && lastYesterdayTime <= ptime) {
        return '前天 ' + time.split(' ')[1]
      } else {
        return time
      }
    }
  },
  components: {
    IconFont
  }
}
</script>

<style lang="scss" scoped>
@import 'variable/variable.scss';
/deep/ .ivu-spin {
  color: $iv-main-color;
}
.iv-titUl {
  display: flex;
  li {
    width: 50%;
    height: 56px;
    line-height: 56px;
    background-color: #f1f2f6;
    &.active {
      background-color: #fff;
    }
    span em {
      background: $iv-main-color;
      color: #fff;
      border-radius: 50%;
      padding: 0px 4px;
      display: inline;
      vertical-align: text-top;
    }
  }
}
.iv-info-wrapper {
  height: 516px;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  background: #fff;
  position: relative;
  cursor: default;
  .iv-meun {
    width: 130px;
    .iv-meun-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #333;
      position: relative;
      &:hover {
        color: $iv-main-color;
      }
      &.iv-active {
        // background: $iv-main-active-color;
        background: #fce7e9;
        color: $iv-main-color;
        font-weight: bold;
        border-right: 3px solid $iv-main-color;
      }
      i {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        position: absolute;
        top: 38%;
        right: 16%;
        display: inline-block;
        background: #f5222d;
      }
    }
  }
  .iv-info {
    // width: 332px;
    width: 460px;
    .iv-info-item {
      // padding:0 12px;
      margin-left: 40px !important;
      position: relative;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      padding: 8px 0px;
      color: #333;
      i.i-tubiao {
        position: absolute;
        left: -24px;
      }
      p {
        max-height: 40px;
        line-height: 20px;
        overflow: hidden;
      }
      &.active p {
        color: #f5222d;
      }
    }
    .iv-operation {
      height: 53px;
      line-height: 53px;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      overflow: hidden;
      color: #333;
      p {
        float: left;
        margin-left: 16px;
        color: $iv-main-color;
      }
      div {
        float: right;
      }
    }
    .iv-no-msg {
      width: 100%;
      height: 409px;
      text-align: center;
      color: #333;
      img {
        margin-top: 77px;
      }
    }
  }
  .iv-custom-icon-time {
    vertical-align: middle;
    margin-top: -3px;
    display: inline-block;
  }
  .top {
    top: -20px;
    right: 78px;
    position: absolute;
  }
  .top:before,
  .top:after {
    position: absolute;
    content: '';
    border-top: 12px transparent dashed;
    border-left: 6px transparent dashed;
    border-right: 6px transparent dashed;
    border-bottom: 10px #fff solid;
  }
  .top:before {
    border-bottom: 10px #fff solid;
  }
  .top:after {
    top: 1px; /*覆盖并错开1px*/
    border-bottom: 10px #fff solid;
  }
  .iv-custom-blue {
    color: #2d8cf0;
  }
  .iv-custom-red {
    color: #ed3f14;
  }
  .ivu-scroll-loader-text {
    color: $iv-main-color;
  }
}
</style>

const ListPage = () => import('@/views/dealt-list')
const historyDone = () => import('@/views/dealt-list/history-done')

export default [
  {
    path: '/dealtList',
    name: 'dealtList',
    component: ListPage,
    meta: {
      title: '我的待办',
      isFirstPage: true
    }
  },
  {
    path: '/historyDone',
    name: 'historyDone',
    component: historyDone,
    meta: {
      title: '我的待办',
      isFirstPage: true
    }
  }
]
import $axios from '@/plugins/axios'
import Button from '@/components/button'
import $confirmModal from '@/components/confirm-modal'
import {Observer, OrgCategory, encode, decode} from '@/utils'

const components = {
  Button
}
const observer = new Observer()
const orgCategory = new OrgCategory()
export default {
  install: (Vue, options) => {
    Object.assign(Vue.prototype, {
      $ajax: $axios,
      $confirmModal,
      $encode: encode,
      $decode: decode
    })
    let hide = Vue.prototype.$Spin.hide
    Vue.prototype.$setRefs = function (name, key, value) {
      /* eslint-disable no-new */
      if (!name || !key) {
        throw new Error(`name and key must be required, error from $setRefs`)
      }
      let ref = this.$refs[name]
      if (!ref) {
        console.error(`${name} not found in $refs`)
        return this
      }
      ref.$set(ref, key, value || '')
    }
    Vue.prototype.$methods = function (name, key, ...args) {
      /* eslint-disable no-new */
      if (!name || !key) {
        throw new Error(`name and key must be required, error from $methods`)
      }
      // 校验数组的某一项
      let i
      [name, i] = name.split('.')
      let ref = this.$refs[name]
      if (!ref) {
        console.error(`${name} not found in $methods`)
        return this
      }
      // vFor中 同名插槽会变成数组
      if (Array.isArray(ref)) {
        if (i) {
          return ref[i][key](...args)
        }
        return Promise.all(ref.map(comp => comp[key](...args)))
      }
      return ref[key] && ref[key](...args)
    }
    Vue.prototype.$Spin.hide = function () {
      let ishidden = document.body.style.overflow === 'hidden'
      hide.apply(this, arguments)
      Vue.nextTick(() => {
        ishidden && (document.body.style.overflow = 'initial')
      })
    }
    Vue.prototype.$Message.config({
      duration: 3
    })
    Vue.prototype.$window = observer
    Vue.prototype.$category = orgCategory
    Object.keys(components).forEach(key => {
      Vue.component(key, components[key])
    })
  }
}
